@font-face {
  font-family: "Calibri";
  src: local("Calibri"),
    url("./assets/fonts/Calibri_Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Calibri-Bold";
  src: local("Calibri"),
    url("./assets/fonts/Calibri_Bold.TTF") format("truetype");
  font-weight: bold;
}

html {
  scrollbar-width: none !important; /* Firefox */
}

body {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  min-height: 100vh;
  overflow-x: hidden;
  font-size: calc(10px + 2vmin);
  color: #3b3b3b !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

body::-webkit-scrollbar {
  display: none !important;
}

body:focus {
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
